<template>
  <div v-if="wvbDataStatus === 'success'" class="d-flex flex-column">
    <portal to="ant-toolbar">
      <module-navigation-bar title="WERKVOLGORDE">
        <template slot="module-nav-actions">
          <v-tabs
            v-model="wvbTab"
            height="46"
            style="width: auto"
            background-color="transparent"
          >
            <v-tab :disabled="!isViewerRendered">
              {{ $t('modules.wvb.phases') }}
            </v-tab>
            <v-tab :disabled="!isViewerRendered">
              {{ $t('modules.wvb.placementSequence') }}
            </v-tab>
            <v-tab :disabled="!isViewerRendered">
              {{ $t('modules.wvb.assemblySequence') }}
            </v-tab>
          </v-tabs>
        </template>
        <template slot="module-nav-logo">
          <img
            src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
            height="40px"
            alt=""
          />
        </template>
      </module-navigation-bar>
    </portal>
    <div class="d-flex flex-1">
      <panel-resizable
        side="left"
        :min-width="100"
        :default-width="600"
        class="ant-glass-background radius-0 ant-border-right overflow-hidden"
        @resize-end="
          $refs['wvb-viewer'].$refs['forge-viewer'].resizeViewEvent()
        "
      >
        <transition name="fade" mode="out-in">
          <div v-if="isViewerRendered" class="flex-grow-1 pa-5 overflow-x-auto" style="height: 0">
            <transition name="slide-fade-down" mode="out-in">
              <w-v-b-phases-sidebar v-if="wvbTab === 0" ref="phases-sidebar" />
              <w-v-b-placement-sidebar
                v-if="wvbTab === 1"
                ref="placement-sidebar"
              />
              <w-v-b-assembly-sidebar
                v-if="wvbTab === 2"
                ref="assembly-sidebar"
              />
            </transition>
          </div>
          <div v-else class="d-flex justify-center align-center flex-grow-1">
            <ant-loading />
          </div>
        </transition>
      </panel-resizable>
      <w-v-b-viewer
        ref="wvb-viewer"
        class="flex-1"
        @clearModuleSelection="resetView()"
        @toggleColorsOff="resetColorToggles"
        @viewerLoaded="isViewerRendered = true"
      />
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center">
    <ant-loading />
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar';
import { mapGetters } from 'vuex';
import { Portal, PortalTarget } from 'portal-vue';
import AntLoading from '@/components/AntLoading';
import { DHME_WVB } from '@/modules/modules';
import WVBPlacementSidebar from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.PlacementSidebar';
import WVBPhasesSidebar from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.PhasesSidebar';
import WVBAssemblySidebar from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.AssemblySidebar';
import WVBViewer from '@/components/Modules/Daiwa-House-Modular-Europe/WVB/DHME.WVB.Viewer';
import PanelResizable from '@/components/Project/PanelResizable.vue';

export default {
  name: 'DHMEWVB',
  components: {
    PanelResizable,
    Portal,
    WVBViewer,
    WVBAssemblySidebar,
    WVBPhasesSidebar,
    WVBPlacementSidebar,
    AntLoading,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      wvbTab: 0,
      isViewerRendered: false,
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'wvbData',
      'wvbDataStatus',
      'wvbModules',
      'wvbNonModules',
    ]),
  },
  watch: {
    wvbTab: {
      handler(value) {
        this.resetConfiguration();
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchWVBModuleData', {
      projectId: this.project.id,
      moduleId: this.project.modules.find((module) => module.route === DHME_WVB)
        .id,
      sessionId: this.$route.params.sessionId ?? null,
    });
    this.$store.dispatch('fetchPlacementPhases');
  },
  methods: {
    resetConfiguration() {
      this.$store.dispatch('resetPhase');
      this.$store.dispatch('resetOrderColumn');

      this.$nextTick(() => {
        this.$refs['wvb-viewer'].playerDisplayed = false;
        this.$refs['wvb-viewer'].configurationMode = false;
        this.$refs['wvb-viewer'].isolatedLayer = false;
        this.$refs['wvb-viewer'].$refs[
          'forge-viewer'
        ].viewerService.resizeView();
        this.$refs['wvb-viewer'].$refs[
          'forge-viewer'
        ].viewerService.clearSearch();
      });
    },
    resetView() {
      switch (this.wvbTab) {
        case 1:
          this.$refs['placement-sidebar'].resetView();
          break;
        case 2:
          this.$refs['assembly-sidebar'].resetView();
          break;
      }
    },

    resetColorToggles() {
      switch (this.wvbTab) {
        case 0:
          this.$refs['phases-sidebar'].colorPhasesToggle = false;
          break;
        case 1:
          this.$refs['placement-sidebar'].colorOrderToggle = false;
          this.$refs['placement-sidebar'].colorPhasesToggle = false;
          break;
        case 2:
          this.$refs['assembly-sidebar'].colorOrderToggle = false;
          this.$refs['assembly-sidebar'].colorPhasesToggle = false;
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
